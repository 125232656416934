$(document).ready(function () {
  $(".toggle-btn").on("click", toggleSections);
});

function toggleSections() {
  var toggle = $(this).data("toggle");
  var toggleContent = $(".toggle-content");

  $(".toggle-btn").removeClass("active");
  $(this).addClass("active");

  toggleContent.each(function (i, item) {
    if ($(item).data("toggle-section") == toggle) {
      toggleContent.removeClass("show-tab-content");
      $(item).addClass("show-tab-content");
    }
  });
}
